import React from 'react'
// Library
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Project1 from '../assets/images/projects/vgcpl_client_adani.png'
import Project2 from '../assets/images/projects/vgcpl_client_agrocel.png'
import Project3 from '../assets/images/projects/vgcpl_client_archen-group.png'
import Project4 from '../assets/images/projects/vgcpl_client_bkt.png'
import Project5 from '../assets/images/projects/vgcpl_client_hp.png'
import Project6 from '../assets/images/projects/vgcpl_client_kutch_chemical.png'
import Project7 from '../assets/images/projects/vgcpl_client_nabros.png'
import Project8 from '../assets/images/projects/vgcpl-client- reliance.png'
import Project9 from '../assets/images/projects/vgcpl-client-amw.png'
import Project10 from '../assets/images/projects/vgcpl-client-ashapura-minechem-ltd.png'
import Project11 from '../assets/images/projects/vgcpl-client-cargill.png'
import Project12 from '../assets/images/projects/vgcpl-client-cgpl.png'
import Project13 from '../assets/images/projects/vgcpl-client-hindusthan.png'
import Project14 from '../assets/images/projects/vgcpl-client-gujarat-tourism.png'
import Project15 from '../assets/images/projects/vgcpl-client-gspc.png'
import Project16 from '../assets/images/projects/vgcpl-client-welspun.png'
import Project17 from '../assets/images/projects/vgcpl-client-swami-mandir-bhuj.png'
import Project18 from '../assets/images/projects/vgcpl-client-l-and-t.png'
import Project19 from '../assets/images/projects/vgcpl-client-indian-oil.png'
import Project20 from '../assets/images/projects/vgcpl-client-ihi.png'
// import Project2 from '../assets/images/projects/shree-sahajanand-gurukul-mankuva-kutch-4.jpg'
// import Project3 from '../assets/images/projects/bhediya-bet-bsf-1.jpg'
// import Project4 from '../assets/images/projects/shree-swaminarayan-temple-bhuj-kutch-2.jpg'
// import Project5 from '../assets/images/projects/shree-sahajanand-gurukul-mankuva-kutch-3.jpg'
// import Project6 from '../assets/images/projects/bhediya-bet-bsf-2.jpg'
// import Project1 from '../assets/images/projects/villa-at-naranpar-pasayati-kutch.jpg'
// import Project2 from '../assets/images/projects/shree-sahajanand-gurukul-mankuva-kutch-4.jpg'
// import Project3 from '../assets/images/projects/bhediya-bet-bsf-1.jpg'
// import Project4 from '../assets/images/projects/shree-swaminarayan-temple-bhuj-kutch-2.jpg'
// import Project5 from '../assets/images/projects/shree-sahajanand-gurukul-mankuva-kutch-3.jpg'
// import Project6 from '../assets/images/projects/bhediya-bet-bsf-2.jpg'

const SliderProjects = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        arrows: false,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings} className='projects'>
                <div className="project-single">
                    <img src={Project1} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4>SHREE SAHAJANAND GURUKUL - MANKUVA (KUTCH)</h4> */}
                </div>
                <div className="project-single">
                    <img src={Project2} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4>BHEDIYA BET <br /> (BSF)</h4> */}
                </div>
                <div className="project-single">
                    <img src={Project3} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4>"VILLA" AT NARANPAR (PASAYATI) KUTCH</h4> */}
                </div>
                <div className="project-single">
                    <img src={Project4} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4>SHREE SAHAJANAND GURUKUL - MANKUVA (KUTCH)</h4> */}
                </div>
                <div className="project-single">
                    <img src={Project5} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4>BHEDIYA BET <br /> (BSF)</h4> */}
                </div>
                <div className="project-single">
                    <img src={Project6} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project7} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project9} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project10} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project8} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project11} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project12} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project13} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project14} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project15} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project16} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project17} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project6} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project18} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project19} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>
                <div className="project-single">
                    <img src={Project20} alt="VGCPL clients | construction company in kutch gujarat" className="img-fluid m-auto" />
                    {/* <h4></h4> */}
                </div>              
            </Slider>
        </div>
    )
}

export default SliderProjects
