import React, { useEffect } from 'react'
import { getInTouch } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import { Container, Col, Row } from 'react-bootstrap';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactForm from '../components/ContactForm'
import { useLocation } from 'react-router-dom';
// import Plant from '../components/Plant';

const GetInTouch = () => {

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
      // if not a hash link, scroll to top
      if (hash === '') {
        window.scrollTo(0, 0);
      }
      // else scroll to id
      else {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    }, [pathname, hash, key]); // do this on route change

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{getInTouch.title}</title>
                <meta name="description" content={getInTouch.description} />
                <link rel="canonical" href={getInTouch.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Get In Touch</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Get In Touch</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <Row>
                        <Col md={4} xs={12} className="contact-info my-auto">
                            <div className="icon mt-unset"><PhoneIcon /></div>
                            <h4>Call</h4>
                            <p>
                                <a href="tel:+91 9909008176">+91 9909008176</a> (Rajesh)
                            </p>
                            <p>
                                <a href="tel:+91 9909228888 ">+91 9909228888 </a> (Hiren)
                            </p>
                            <p>
                                <a href="tel:+91 9099017240  ">+91 9099017240  </a> (Mansukh)
                            </p>

                            <div className="icon"><EmailIcon /></div>
                            <h4>Email</h4>
                            <p><a href="mailto:info@vgcpl.com">info@vgcpl.com</a></p>
                            <div className="icon"><RoomIcon /></div>
                            <h4>Address</h4>
                            <p>54 & 55 ,Patel Nagar, <br /> At. Post: Baladia, <br />Taluka: Bhuj, <br /> District: Kutch, <br />State: Gujarat, <br />Pincode: 370427 </p>
                        </Col>
                        <Col md={8}>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <section className='map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1541.8697080717259!2d69.6042923205454!3d23.199203880532746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSur.%20No.%20158%2F2P%2C%20%20Opp.%20Shivparas%20Temple%2C%20At%20Post%3A%20Sukhpar%2C%20370040%20Ta.%20Bhuj%20-%20Kutch%20(Gujarat)!5e0!3m2!1sen!2sin!4v1650109039525!5m2!1sen!2sin" title={'Map Location'} width="100%" height="500" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section> */}
        </div>
    )
}

export default GetInTouch
