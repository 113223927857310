const defaultTitle = 'Civil Construction Company in Gujarat, India | VGCPL'
const defaultDescription = 'VGCPL has become one of the most trusted construction companies in the state of Gujarat, India, for more than ten years with the latest technology, state-of-the-art machinery and qualified human resources.'
const siteUrl = 'https://vgcpl.co.in/'

export const homepage = {
    title: defaultTitle,
    description: defaultDescription,
    canonical: siteUrl
}

export const business = {
    title: 'About our Business | VGCPL ',
    description: 'We are one of the most trusted civil construction company with full dedication & passion to work for the utmost satisfaction of our Valued Customers.',
    canonical: siteUrl + 'business'
}

export const vconcrete = {
    title: 'VConcrete | RMC Plant manufacturer in Gujarat | VGCPL',
    description: 'At VGCPL we provide all the world-class construction services, and our quality of work speaks much better than us. We are also known as leading supplier of Ready Mixed Concrete (RMC) in the district of Kachchh, Gujarat, India.',
    canonical: siteUrl + 'gallery'
}

export const gallery = {
    title: 'Our Work Gallery | VGCPL',
    description: 'We are one of the most trusted civil construction company with full dedication & passion to work for the utmost satisfaction of our Valued Customers.',
    canonical: siteUrl + 'gallery'
}

export const plants = {
    title: 'Our Plants | VGCPL',
    description: 'We have excelled in producing Ready Mix Concrete in our own plants at various locations to fulfil our own demands and also for sale in the market.',
    canonical: siteUrl + 'plants'
}

export const projects = {
    title: 'VConcrete | Vishrambhai Gorasia Construction Pvt. Ltd.',
    description: 'VConcrete | Vishrambhai Gorasia Construction Pvt. Ltd.',
    canonical: siteUrl + 'projects'
}

export const getInTouch = {
    title: 'Contact VGCPL | VGCPL',
    description: 'If you interested in our work include our Service & Products, you can deal with us via contact information. For other inquiries, please contact us.',
    canonical: siteUrl + 'get_in_touch'
}

export const privacyPolicy = {
    title: 'Privacy Policy | VGCPL',
    description: 'The personal information we collect allows us to keep you posted on VGCPL latest Services and updates. VGCPL and its affiliates may share personal information with each other and use it consistent with this Privacy Policy.',
    canonical: siteUrl + 'privacy-policy'
}

export const thankYou = {
    title: 'Thank You | Vishrambhai Gorasia Construction Pvt. Ltd.',
    description: 'Thank You | Vishrambhai Gorasia Construction Pvt. Ltd.',
    canonical: siteUrl + 'thank-you'
}
