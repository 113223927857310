import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const GetInTouch = () => {
    return (
        <div>
            <section className="touch bg-2">
                <div className="bgc-2">
                    <Container>
                        <Row>
                            <Col md={9} className="my-auto">
                                <h2>Looking for Civil Construction works or  <br />ready-mix concrete ?</h2>
                            </Col>
                            <Col md={3} className="text-center my-auto">
                                <Link to="/get_in_touch" className="btn btn-primary">Get In touch <ArrowRightAltIcon /></Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>
    )
}

export default GetInTouch
