import React, { useEffect } from 'react'
import { vconcrete } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import ContactSection from '../components/ContactSection'
import { Container, Col, Row } from 'react-bootstrap'
import image1 from '../assets/images/vconcrete/overview.png'
import image2 from '../assets/images/vconcrete/v_concrete_history.png'
import image3 from '../assets/images/vconcrete/vconcrete_truck.png'
import { useLocation } from 'react-router-dom';

const Business = () => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {

      if (hash === '') {
        window.scrollTo(0, 0);
      }
      // else scroll to id
      else {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    }, [pathname, hash, key]); // do this on route change

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{vconcrete.title}</title>
                <meta name="description" content={vconcrete.description} />
                <link rel="canonical" href={vconcrete.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>VConcrete</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>VConcrete</li>
                    </ul>
                </div>
            </section>
            <section id='about' className="entry section-bg">
                <Container className='bg-4'>
                    <Row>
                        <Col md={4} className='text-center centered-image-parent'>
                            <img src={image1} alt="best construction company in kutch, gujarat | VGCPL" className="img-fluid bg" />
                        </Col>
                        <Col md={8}>
                            <h2>O<span>verview</span></h2>
                            <div className="content">
                              <p>‘Excellence’ is the only word to describe us. After serving our motherland Kachchh in field of construction and ready-mix concrete for 10 long years, now we at VConcrete feel extremely proud to be known as one of the most reliable and trusted construction masters of Gujarat.</p>
                              <p>V Concrete as a part of Vishrambhai Gorasia Construction Pvt. Ltd. (VGCPL) group, is an established name in the field of construction. At VGCPL we provide all the world-class construction services, and our quality of work speaks much better than us.</p>
                              <p>We are also known as leading supplier of Ready Mixed Concrete (RMC) in the district of Kachchh, Gujarat, India, and for the same we have established five plants in the district of Kachchh, Gujarat, India, to meet the progressing demand of RMC. After Working for renowned companies like Adani, AMW, Archean etc there is nothing much left to prove us.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="part-2">
                        <Row>
                            <Col md={8}>
                                <h2>V<span>Concrete</span></h2>
                                <div className="content">
                                    <p>VConcrete have its basis in Baladia Village of Bhuj Tehsil in Kachchh district. VConcrete under the guidance of Vishram Karsanbhai Gorasia and under the dynamic leadership of Jadavji Gorasia, Parbat Gorasia and Bharat Gorasia is climbing the new heights in field of construction.VConcrete is the top most manufacturer and supplier of RMC in the district of Kachchh.
</p>
                                    <p>Achieving the milestone of being top construction company of Kachchh, Now we are planning to take this business on the national horizon to serve best in field of concrete construction.</p>
                                    <p>Many big manufacturing and infrastructure firms, Agri-Buinesses, Logistics, Container freight services are in the list of our satisfied clients. With this rich and strong background in field of construction we at VConcrete always like to grow and nurture ourselves more and more to achieve excellence in whatever we do.</p>
                                   <p>Quality of RMC is directly dependent on the raw material used and the type of mixer plant and VConcrete is having one of the finest human resources available on its sites.With experienced eyes of mentors and qualified professionals we at VConcrete and VGCPL believe in no compromise policy in our quality of work. Innovating and learning is what we appreciate and hence we follow the same in our business, weather it be through technology, through machinery or even if it is achieved through the means of human resources.</p> 
                                <p>To meet the increasing demand of ready mix concrete and everyday expanding construction business, VConcrete recently thought of starting new unit in Kachchh and so now we have two very well equipped and technically sound working units. With our versatility and quality service combed with experience and expertise we at VConcrete are always on standby mode to serve you The Best.</p>
                                </div>
                            </Col>
                            <Col md={4} className='text-center centered-image-parent change-order'>
                                <img src={image2} alt="VConcrete - a unit of VGCPL" className="img-fluid bg" />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section id='about' className="entry ">
                <Container>
                    <Row>
                        <Col md={4} className='text-center centered-image-parent'>
                            <img src={image3} alt="History of VGCPL" className="img-fluid bg" />
                        </Col>
                        <Col md={8}>
                            <h2>H<span>istory</span></h2>
                            <div className="content">
                            <p>Mr. Vishram Karsanbhai Gorasia, the founder and MD of VGCPL started his career of civil contractor with very small construction work but with very large insight. Gradually with his dedication, hard work and passion to do best in field he established himself as a renowned civil contractor in the land of Kachchh. With his extraordinary vision he climbed the highest peak of success and worked his days out to establish some of the best construction structures. Later on, he retired him-self from this job and dedicated his time for social work. This simply shows one of the humanitarian side of his persona.</p>
                                    <p>Carrying forward the legacy of Vishrambhai Gorasia, his elder son Mr. Jadavji Gorasia gave a completely modernized structure to family driven construction business by registering it as Vishrambhai Gorasia Construction Private Limited i.e. VGCPL in 2008</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactSection />
        </div>
    )
}

export default Business
