import React from 'react'
import { Carousel } from 'react-bootstrap'
import Slider1 from '../assets/images/Slider1.jpg'
import Slider2 from '../assets/images/vgcpl-slider-2.png'
import Slider3 from '../assets/images/vgcpl-slider-3.png'

const Slider = () => {
    return (
        <div>
            <div className="slider">
                <Carousel controls={true} interval={3000} indicators={false}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider1} alt="construction company in gujarat | VGCPL" />
                    </Carousel.Item>        
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider2} alt="civil construction company in gujarat | VGCPL" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider3} alt="best construction company in gujarat | VGCPL" />
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default Slider
