import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Vision1 from '../assets/images/vision-1.svg'
import Vision2 from '../assets/images/vision-2.svg'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Diversity1Icon from '@mui/icons-material/Diversity1';

export default function MissionAndVision() {
    return (
        <section className="mission">
            <Container>
                <div className="text-center">
                    <h2 className='center'>What is <span>VGCPL</span></h2><br />
                    <p>What makes us the best choice</p>
                </div>
                <Row>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-1">
                            <table>
                                <tr>
                                    <td>
                                        <div className="icon-box">
                                            <Diversity1Icon className='hover-5' style={{fontSize:'80px',padding:'15px'}}/>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <h3>Team Work</h3>
                            <p>Our team work is like working for a common goal to be achieved by joint efforts – starting from the unskilled labour to the Project Manager – duly monitored by our Directors on day-to-day basis.<br/> </p>
                        </div>
                    </Col>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-2">
                            <table>
                                <tr>
                                    <td>
                                        <div className="icon-box">
                                            <MiscellaneousServicesIcon className='hover-4' style={{fontSize:'80px',padding:'15px'}}/>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <h3>Sevices</h3>
                            <p>Our Services include various Civil Construction works like Industrial Infrastructure Projects, Commercial houses & offices and other projects as per the need of our valued clients i.e. Adani Group, Reliance Group and many more.<br/> </p> 
                        </div>
                    </Col>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-3">
                            <table>
                                <tr>
                                    <td>
                                        <div className="icon-box">
                                            <img src={Vision1} alt="rmc manufacturer in gujarat | VGCPL" className="or-3" />
                                            <img src={Vision2} alt="bridge construction company in gujarat | VGCPL" className="hover-3" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <h3>Vision</h3>
                            <p>Our vision is very broad and we focus on to grow more and more and leave no stone unturned in the field of Civil construction with the best of our abilities using the latest state – of – the – art - technology. <br/> </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
