export const menu = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'Business',
        path: '/business'
    },
    {
        label: 'VConcrete',
        path: '/vconcrete'
    },
    {
        label: 'Plants',
        path: '/plants'
    },
    {
        label: 'Gallery',
        path: '/gallery'
    },
    {
        label: 'Get In Touch',
        path: '/get_in_touch'
    }
]
