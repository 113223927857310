import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import RoomIcon from "@mui/icons-material/Room";
import { plants } from "../MetaTags";
import MetaTags from "react-meta-tags";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MachineIcon from "@mui/icons-material/PrecisionManufacturing";
import khavdaPlant from "../assets/images/galleryvgcpl/khavda-plant-img1.png";

import { useLocation } from "react-router-dom";

export default function Plants(props) {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>{plants.title}</title>
        <meta name="description" content={plants.description} />
        <link rel="canonical" href={plants.canonical} />
      </MetaTags>
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <h1>Plants</h1>
          <ul>
            <li>
              <a href="./">Home</a>
            </li>
            <li>Plants</li>
          </ul>
        </div>
      </section>
      <section id="desalpar">
        <Container>
          <Row>
            <Col md={6}>
              <h2>
                Desalpar <span>Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>M1 KYB Conmat</p>
                <p>MP 30 Aquarius</p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <p>
                  Rajeshbhai Buchiya <br />{" "}
                  <a href="tel:+91 9909008176">+91 9909008176</a>
                </p>
                {/* <br /> */}
                {/* <a href="tel:+912832282316">+912832282316</a> */}
              </div>
              {/* <div className="contacts">
                            <div class="icon">
                                <EmailIcon />
                            </div>
                            <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
                        </div> */}
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  Rev. Sur.No. 390/P1, <br />
                  Adani Power Plant Road,
                  <br />
                  At: DESALPAR, <br /> Taluka: Mundra , Kutch, Gujarat
                </p>
              </div>
            </Col>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3676.072699099804!2d69.5706278!3d22.873772700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39512dfb55ecd161%3A0x3f521b730c4517b0!2sV%20concrete%20Mundra%20Plant%202!5e0!3m2!1sen!2sin!4v1673589587652!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="gajod" className="section-bg">
        <Container>
          <Row>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.323023294317!2d69.608482!3d23.0486161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x36597d87a1d46d8c!2sV%20concrete%20unit%201!5e0!3m2!1sen!2sin!4v1673587641439!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col md={6}>
              <h2>
                Gajod <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Stetter</p>
                <p>M1 Schwing Stetter</p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <p>
                  Rajeshbhai Buchiya <br />{" "}
                  <a href="tel:+91 9909008176">+91 9909008176</a>
                </p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  Rev. Sr. No. 225/1, <br /> Bhuj Mundra Highway Road,
                  <br /> At Post: <b>Gajod</b>, <br />
                  Tal: Bhuj, Dist. : Kutch, Gujarat. <br />
                  Pin Code:370430{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="siracha">
        <Container>
          <Row>
            <Col md={6}>
              <h2>
                Siracha <span> Plant</span>
              </h2>
              <br />
              {/* <p>Some text about Galpadar plant/office</p> */}
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Sletter (Sarrs Constructions)</p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <p>
                  Rajeshbhai Buchiya <br />{" "}
                  <a href="tel:+91 9909008176">+91 9909008176</a>
                </p>
              </div>
              {/* <div className="contacts">
                            <div class="icon">
                                <EmailIcon />
                            </div>
                            <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
                        </div> */}
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  SVCT Township, <br /> At: <b>SIRACHA,</b>
                  <br /> Taluka: Mundra , Kutch, Gujarat, <br />
                  Pin Code: 370405
                  <br />{" "}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3676.632258011205!2d69.5707216!3d22.8530911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39512b4f84f5f893%3A0xdc26f57eb066dea!2sV%20concrete%20Mundra!5e0!3m2!1sen!2sin!4v1673587818557!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="kandla" className="section-bg">
        <Container>
          <Row>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.2405020566425!2d70.2146722!3d23.0149401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b1dc5e53e82d%3A0x726eac00195251f6!2sV%20concrete%20Kandla!5e0!3m2!1sen!2sin!4v1673589108101!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col md={6}>
              <h2>
                Kandla <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Stetter (Sanghi Cement)</p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <p>
                  Rajeshbhai Buchiya <br />{" "}
                  <a href="tel:+91 9909008176">+91 9909008176</a>
                </p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  {" "}
                  Near Sea Rock Hotel,
                  <br /> Behind IOCL Petrol Pump,
                  <br /> At: <b>KANDLA</b>,<br />
                  Taluka: Gandhidham, Kutch, Gujarat,
                  <br />
                  Pin Code: 370210
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="khavda" className="section-bg">
        <Container>
          <Row>
            <Col md={6}>
              <h2>
                Khavda <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Stetter</p>
                <p>M1 Schwing Stetter</p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <p>
                  Rajeshbhai Buchiya <br />{" "}
                  <a href="tel:+91 9909008176">+91 9909008176</a>
                </p>
                <p>
                  Mansukh Bhai <br />{" "}
                  <a href="tel:+91 9099017240">+91 9099017240</a>
                </p>
                <p>
                  Hirenbhai Joshi <br />{" "}
                  <a href="tel:+91 9909228888">+91 9909228888</a>
                </p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  RE Park, <br /> Shaktibet,Khavda,
                  <br /> At Post: <b>Khavda</b>, <br />
                  Tal. khavda, Dist. : Kutch, Gujarat. <br />
                  Pin code: 370510{" "}
                </p>
              </div>
            </Col>
            <Col md={6} className="text-center centered-image-parent">
              <iframe
                title="VGCPL Registered Khavda Plant google map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d545551.8591676202!2d69.706262989721!3d23.923304318374385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDAzJzQ0LjYiTiA2OcKwMzAnMjMuOCJF!5e0!3m2!1sen!2sin!4v1704432201614!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="gandhidham">
        <Container>
          <Row>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.897564243571!2d70.08028469999999!3d23.100845399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b8914dbbf0d7%3A0x334fd5dd958f2c4b!2sV%20Concrete!5e0!3m2!1sen!2sin!4v1673589239316!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col md={6}>
              <h2>
                Gandhidham <span> Plant</span>
              </h2>
              <br />
              {/* <p>Some text about Galpadar plant/office</p> */}
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                <b>
                  <p>Machinaries</p>
                </b>
                <p>MP 70 Aquarius</p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <p>
                  Kamleshbhai Sakaliya <br />{" "}
                  <a href="tel:+91 8141399888">+91 8141399888</a>
                </p>
              </div>
              {/* <div className="contacts">
                            <div class="icon">
                                <EmailIcon />
                            </div>
                            <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
                        </div> */}
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  Plot No.9 &10, <br /> Rev.Sur.No. 57, <br /> At & Post:{" "}
                  <b>MEGHPAR BORICHI</b>
                  <br /> Taluka: Anjar, Kutch, Gujarat, <br />
                  Pin Code: 370110
                  <br />{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
