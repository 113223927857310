// Core
import React from 'react'
// Library
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages
import Homepage from './pages/Homepage'
import Business from './pages/Business'
import Plants from './pages/Plants'
// import Values from './pages/Values'
import Vconcrete from './pages/Vconcrete'
import GetInTouch from './pages/GetInTouch'
import Gallery from './pages/Gallery'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ThankYou from './pages/ThankYou'

function App() {
    return (
        <div>
            <Router>
                <Header />
                <Routes>
                    <Route exact path='/' element={<Homepage />} />
                    <Route exact path='/plants' element={<Plants />} />
                    <Route exact path='/business' element={<Business />} />
                    <Route exact path='/vconcrete' element={<Vconcrete />} />
                    {/* <Route exact path='/values' element={<Values />} /> */}
                    <Route exact path='/gallery' element={<Gallery />} />
                    <Route exact path='/get_in_touch' element={<GetInTouch />} />
                    <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route exact path='/thank-you' element={<ThankYou />} />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
