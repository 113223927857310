import React, { useEffect } from 'react'
import { business } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import ContactSection from '../components/ContactSection'
import { Container, Col, Row } from 'react-bootstrap'
import image1 from '../assets/images/business/kandla-port-concrete-suppler.png'
import image2 from '../assets/images/business/orrigation-project-vgcpl.png'
import image3 from '../assets/images/business/port-development-kutch-by-vgcpl.png'
import image4 from '../assets/images/business/road-project-by-vgcpl.png'
import image5 from '../assets/images/vgcpl-2.png'
import image6 from '../assets/images/galleryvgcpl/v-concrate-project.png'

const Business = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{business.title}</title>
                <meta name="description" content={business.description} />
                <link rel="canonical" href={business.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Business</h1>                   
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Business</li>
                    </ul>
                </div>
            </section>
            <section id='about' className="entry section">
                <Container>
                    <Row>
                        <Col md={4} className='text-center centered-image-parent'>
                            <img src={image1} alt="Civil construction company in gujarat | VGCPL" className="img-fluid bg" />
                        </Col>
                        <Col md={8}>
                            <h2>Civil <span> Engineering </span></h2>
                            <div className="content">
                                <p>As far as civil engineering is concerned, we have with us qualified & experienced Engineers, Architects, Draftsman, On-site Supervisors along with Skilled & Unskilled team of labours and State-of-the-Art machineries, to work for the projects undertaken by us, whether Residential or Commercial projects. </p>
                                <p>We are one of the most trusted civil construction co. with full dedication & passion to work for the utmost satisfaction of our Valued Customers.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="part-2">
                        <Row>
                            <Col md={8}>
                                <h2>Industrial <span> Project </span></h2>
                                <div className="content">
                                   <p>Our core success lies in working for Industrial projects of Business giants like Adani group, Reliance Industries and many more. </p>
                                   <p>We have also undertaken projects of State Government and Indian Railways at various locations.</p>
                                </div>
                            </Col>
                            <Col md={4} className='text-center centered-image-parent change-order'>
                                <img src={image6} alt="Industrial construction company in gujarat | VGCPL" className="img-fluid bg" />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section id='about' className="entry section">
                <Container >
                    <div className="part-2">
                        <Row>
                        <Col md={4} className='text-center centered-image-parent change-order'>
                                <img src={image4} alt="road and bridges construction company in gujarat | VGCPL" className="img-fluid bg" />
                            </Col>
                            <Col md={8}>
                                <h2>Road and Bridges <span>  Construction </span></h2>
                                <div className="content">
                                   <p>With our expertise we have undertaken several government & semi government contracts to construct both Roads & Bridges in Urban as well as Rural remote areas in the state of Gujarat.</p>
                                </div>
                            </Col>
                           
                        </Row>
                    </div>
                </Container>
            </section>
            <section id='about' className="entry section-bg">
                <Container className='bg-1'>
                    <Row>
                       
                        <Col md={8}>
                            <h2>Earth <span>  Works </span></h2>
                            <div className="content">
                                <p>We have a dedicated team only to work for earth excavations, fillings, dressings & levelling of any type of soil, Rock – Laterite – Soft , with the latest machineries available with us, we don’t leave any Stone unturned.</p>
                            </div>
                        </Col>
                        <Col md={4} className='text-center centered-image-parent'>
                            <img src={image5} alt="rmc manufacturer in gujarat | VGCPL" className="img-fluid bg" />
                        </Col>
                    </Row>
                    <div className="part-2">
                        <Row>
                        <Col md={4} className='text-center centered-image-parent change-order'>
                                <img src={image2} alt=" Irrigation projects constrution company in kutch, gujarat | VGCPL" className="img-fluid bg" />
                            </Col>
                            <Col md={8}>
                                <h2>Irrigation <span>  Project </span></h2>
                                <div className="content">
                                    <p>We our known for exceptional success in different Irrigation Projects, from small farm reservoirs to big canals & dams including State Government contracts, to the utmost satisfaction of our valued customers.</p>
                                </div>
                            </Col>
                           
                        </Row>
                    </div>
                    <div className="part-2">
                    <Row>
                        <Col md={8}>
                            <h2>Ready Mix Concrete <span> (RMC) </span></h2>
                            <div className="content">
                                <h3>(Our sister concern-V Concrete)</h3>
                                <p>After achieving good & progressive response in construction field, we have excelled in producing Ready Mix Concrete in our own plants at various locations to fulfil our own demands and also for sale in the market. For more details you can visit our Website <a href="https://vconcrete.in/" target="_blank" rel="noopener noreferrer">VConcrete</a> </p>
                            </div>
                        </Col>
                        <Col md={4} className='text-center centered-image-parent'>
                            <img src={image3} alt="Ready Mix Concrete manufacturer in kutch, gujarat | VGCPL" className="img-fluid bg" />
                        </Col>
                    </Row>
                    </div>
                </Container>
            </section>
            <ContactSection/>
        </div>
    )
}

export default Business
