import React, { useState, useEffect } from "react";
import { gallery } from "../MetaTags";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import GalleryImg2 from "../assets/images/galleryvgcpl/building-material.png";
import GalleryImg3 from "../assets/images/galleryvgcpl/construction-company-bhuj.png";
import GalleryImg4 from "../assets/images/galleryvgcpl/construction-company-gujarat.png";
import GalleryImg5 from "../assets/images/galleryvgcpl/construction-machinary-by-vgcpl-kutch.png";
import GalleryImg6 from "../assets/images/galleryvgcpl/construction-machineary.png";
import GalleryImg7 from "../assets/images/galleryvgcpl/construction-technology.png";
import GalleryImg8 from "../assets/images/galleryvgcpl/construction-tmt-iorn.png";
import GalleryImg9 from "../assets/images/galleryvgcpl/gujarat-port-development-kutch.png";
import GalleryImg10 from "../assets/images/galleryvgcpl/heavy-engineering-port-construction-company.png";
import GalleryImg11 from "../assets/images/galleryvgcpl/kandla-port-construction-project.png";
import GalleryImg12 from "../assets/images/galleryvgcpl/kutch-development-progress.png";
import GalleryImg13 from "../assets/images/galleryvgcpl/kutch-development.png";
import GalleryImg14 from "../assets/images/galleryvgcpl/kutch-gujarat-construction-machinery.png";
import GalleryImg15 from "../assets/images/galleryvgcpl/kutch-infrastructure-development.png";
import GalleryImg16 from "../assets/images/galleryvgcpl/kutch-rmd-supplier-bhuj.png";
import GalleryImg17 from "../assets/images/galleryvgcpl/machinary-kutch-bhuj-mandir-saint.png";
import GalleryImg18 from "../assets/images/galleryvgcpl/machinery-by-vgcpl.png";
import GalleryImg19 from "../assets/images/galleryvgcpl/mundra-construction-project-by-vgcpl.png";
import GalleryImg20 from "../assets/images/galleryvgcpl/port-development-kutch-by-vgcpl.png";
import GalleryImg21 from "../assets/images/galleryvgcpl/project-kutch.png";
import GalleryImg22 from "../assets/images/galleryvgcpl/rmc-machinery-kutch-gujarat.png";
import GalleryImg23 from "../assets/images/galleryvgcpl/road-construction-by-vgcpl.png";
import GalleryImg24 from "../assets/images/galleryvgcpl/road-construction-in-kutch.png";
import GalleryImg25 from "../assets/images/galleryvgcpl/road-construction-vgcpl.png";
import GalleryImg26 from "../assets/images/galleryvgcpl/top-construction-company-in-kutch.png";
import GalleryImg27 from "../assets/images/galleryvgcpl/top-gujarat-constrauction-company.png";
import GalleryImg28 from "../assets/images/galleryvgcpl/top-kutch-construction-company.png";
import GalleryImg29 from "../assets/images/galleryvgcpl/top-rmc-supplayer-kutch.png";
import GalleryImg30 from "../assets/images/galleryvgcpl/v-concrate-project.png";
import GalleryImg31 from "../assets/images/galleryvgcpl/vgcpl-construction-company-work.png";
import GalleryImg32 from "../assets/images/galleryvgcpl/vgcpl-construction-project.png";
import GalleryImg33 from "../assets/images/galleryvgcpl/vgcpl-construction-work-in-progress.png";
import GalleryImg34 from "../assets/images/galleryvgcpl/vgcpl-gujarat-plant-setup-vconcrete.png";
import GalleryImg35 from "../assets/images/galleryvgcpl/vgcpl-vconcrete-plant-steup.png";
import GalleryImg36 from "../assets/images/galleryvgcpl/vgcpl.png";
import GalleryImg37 from "../assets/images/galleryvgcpl/vconcrete-machinary.png";
import GalleryImg38 from "../assets/images/galleryvgcpl/khavda-plant-img1.png";
import GalleryImg39 from "../assets/images/galleryvgcpl/khavda-plant-img2.png";
import GalleryImg40 from "../assets/images/galleryvgcpl/khavda-plant-img3.png";
import GalleryImg41 from "../assets/images/galleryvgcpl/khavda-plant-img4.png";
import GalleryImg42 from "../assets/images/galleryvgcpl/khavda-plant-img5.png";
import GalleryImg43 from "../assets/images/galleryvgcpl/khavda-plant-img6.png";
import GalleryImg44 from "../assets/images/galleryvgcpl/khavda-plant-img7.png";
import GalleryImg45 from "../assets/images/galleryvgcpl/khavda-plant-img8.png";

import ContactSection from "../components/ContactSection";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const galleryImages = [
  GalleryImg2,
  GalleryImg3,
  GalleryImg4,
  GalleryImg5,
  GalleryImg6,
  GalleryImg7,
  GalleryImg8,
  GalleryImg9,
  GalleryImg10,
  GalleryImg11,
  GalleryImg12,
  GalleryImg13,
  GalleryImg14,
  GalleryImg15,
  GalleryImg16,
  GalleryImg17,
  GalleryImg18,
  GalleryImg19,
  GalleryImg20,
  GalleryImg21,
  GalleryImg22,
  GalleryImg23,
  GalleryImg24,
  GalleryImg25,
  GalleryImg26,
  GalleryImg27,
  GalleryImg28,
  GalleryImg29,
  GalleryImg30,
  GalleryImg31,
  GalleryImg32,
  GalleryImg33,
  GalleryImg34,
  GalleryImg35,
  GalleryImg36,
  GalleryImg37,
  GalleryImg38,
  GalleryImg39,
  GalleryImg40,
  GalleryImg41,
  GalleryImg42,
  GalleryImg43,
  GalleryImg44,
  GalleryImg45,
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [finalImage, setFinalImage] = useState(null);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = (image) => {
    setFinalImage(image);
    setOpen(true);
  };

  return (
    <div>
      <MetaTags>
        <title>{gallery.title}</title>
        <meta name="description" content={gallery.description} />
        <link rel="canonical" href={gallery.canonical} />
      </MetaTags>
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <h1>Gallery</h1>
          <ul>
            <li>
              <a href="./">Home</a>
            </li>
            <li>Gallery</li>
          </ul>
        </div>
      </section>

      <section className="gallery">
        <Container>
          <br />
          <h2 className="text-center">Gallery</h2>
          <br />
          <br />
          <Row>
            {galleryImages.map((image, index) => {
              return (
                <Col key={index} md={4}>
                  <img
                    onClick={() => handleOpen(image)}
                    src={image}
                    alt="Ready Mix Concrete Manufacturer in kutch, gujarat | VGCPL"
                    className="img-fluid"
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={finalImage}
            alt="civil construction company in kutch gujarat | VGCPL"
            className="img-fluid"
          />
        </Box>
      </Modal>
      <ContactSection />
    </div>
  );
};

export default Gallery;
