// Core
import React from 'react'
import { Link } from "react-router-dom";
// BootStrap
import { Container, Col, Row } from 'react-bootstrap';
import FacebookIcon from '@mui/icons-material/Facebook';
// import  WhatsAppIcon  from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RoomIcon from '@mui/icons-material/Room';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Logo from '../assets/images/logo/vgcpl_logo_dark.png';
import LaunchIcon from '@mui/icons-material/Launch';

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <div>
            <div id="footer">
                <Container>
                    <Row>
                        <Col md={3}>
                            <img src={Logo} alt="civil construction company in gujarat | VGCPL" className='logo' />
                            <p>VGCPL is the most trusted Civil Construction Co. in the state of Gujarat, India.</p>
                            <div className="social">
                                <ul>
                                    <li>
                                        <a href="https://www.instagram.com/vgcpl/" target='_blank'>
                                            <InstagramIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=61555368070654">
                                            <FacebookIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/101479710/admin/feed/posts/">
                                            <LinkedInIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/vgcpl_in">
                                            <TwitterIcon />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="mailto:info@vgcpl.com">
                                            <EmailIcon />
                                        </a>
                                    </li>
                                     */}
                                </ul>
                            </div>
                        </Col>
                        <Col md={2}>
                            <h5>Quick Links</h5>
                            <ul>
                                <li><h6><Link to="/">Home</Link></h6></li>
                                <li><h6><Link to="/plants">Plants</Link></h6></li>
                                <li><h6><Link to="/business">Business</Link></h6></li>
                                <li><h6><Link to="/vconcrete">VConcrete</Link></h6></li>
                                <li><h6><Link to="/gallery">Gallery</Link></h6></li>
                                <li><h6><Link to="/get_in_touch">Get In Touch</Link></h6></li>
                                {/* <li><h6><Link to="/privacy-policy">Privacy Policy</Link></h6></li> */}
                            </ul>
                        </Col>
                        {/* <Col md={3} className='contact'>
                            <h5>Registered Office</h5>
                            <RoomIcon /><p> Patel Nagar,<br /> At. Post: Baladia,<br />  Bhuj (Kutch) Gujarat.<br /> Pincode: 370427 </p>
                            <LocalPhoneIcon /><p><a href="tel: +912832282316"> +91 2832 282316</a></p>
                            <EmailIcon /><p><a href="mailto:info@vgcpl.com">info@vgcpl.com</a></p>
                        </Col> */}

                        <Col md={3} className='contact'>
                            <h5>Registered Office</h5>
                            <RoomIcon /><p>54 & 55 ,Patel Nagar,<br /> At. Post: Baladia,<br /> Taluka:Bhuj,<br /> District: Kutch, 
                            <br />State: Gujarat, <br />Pincode: 370427 </p>
                            <LocalPhoneIcon /><p><a href="tel: +91 9909008176">+91 9909008176</a></p>
                            <EmailIcon /><p><a href="mailto:info@vgcpl.com">info@vgcpl.com</a></p>
                        </Col>
                        <Col md={3} className='contact'>
                            <h5>Plants Locations</h5>
                            <Link to="/plants#desalpar"><LaunchIcon fontSize='medium'/> &nbsp;Desalpar Plant</Link><br/><br/> 
                            <Link to="/plants#gajod"><LaunchIcon fontSize='medium'/> &nbsp;Gajod Plant</Link><br/><br/>
                            <Link to="/plants#siracha"><LaunchIcon fontSize='medium'/> &nbsp;Siracha Plant</Link><br /> <br />
                            <Link to="/plants#kandla"><LaunchIcon fontSize='medium'/> &nbsp;Kandla Plant</Link><br /><br />
                            <Link to="/plants#gandhidham"><LaunchIcon fontSize='medium'/> &nbsp;Gandhidham Plant</Link><br /><br />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="copyright text-center">
                <p>&copy; {currentYear} VGCPL All rights reserved. Website designed and developed by Arkay Apps.|<a href="/privacy-policy">Privacy Policy</a></p>
            </div>
            <div className="bottom"></div>
        </div>
    )
}

export default Footer