// Core
import React, { useEffect } from 'react'
import { homepage } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import Slider from '../components/Slider'
import ContactSection from '../components/ContactSection'
import MissionAndVision from '../components/MissionAndVision'
import { Container, Col, Row } from 'react-bootstrap'
import About from '../assets/images/vgcpl-2.png'
import historyVGCPL from '../assets/images/history-vgcpl.png'
import Vehicle1 from '../assets/images/Mixer-2.png'
import GetInTouch from '../components/GetInTouch'
import SliderProjects from '../components/SliderProjects'
import Marquee from "react-fast-marquee";

const Homepage = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{homepage.title}</title>
                <meta name="description" content={homepage.description} />
                <link rel="canonical" href={homepage.canonical} />
            </MetaTags>
            <Slider />
            <section id='about' className="entry section-bg">
                <Container className='bg-4'>
                    <Row>
                        <Col md={6}>
                            <h2>About <span>US</span></h2>
                            <div className="content">
                                <p><strong>VGCPL</strong> has become one of the most <b><i>TRUSTED </i></b>construction companies in the state of Gujarat (India), after serving in the district of Kutch (Gujarat), for more than ten long years with latest technology, state-of-the-art machineries and qualified human resources.</p>
                                <p>Being in the field of construction  and regular want of CONCRETE, The Company, with its expansion plans established its own Ready Mix Plants at several locations given below.</p>
                            </div>
                        </Col>
                        <Col md={6} className='text-center centered-image-parent change-order'>
                            <img src={About} alt="About VGCPL | rmc manufacturer in kutch, gujarat" className="img-fluid bg" />
                        </Col>
                    </Row>
                    <div className="part-2">
                        <Row>
                            <Col md={6} className='text-center centered-image-parent'>
                                <img src={historyVGCPL} alt="History of best construction company in kutch, gujarat" className="img-fluid bg" />
                            </Col>
                            <Col md={6}>
                                <h2>Our <span>History</span></h2>
                                <div className="content">
                                    <p>Late Sri Vishram Karsanbhai Gorasia, the founder, later on became the Managing Director of the Company (VGCPvt.Ltd.), at the very onset of his career, he started to undertake very small construction ventures in his own home village (Baladia – Taluka: Bhuj – District: Kutch – Gujarat – India), but with a very <b><i>BIG</i></b> foresight & <b><i>WIDE </i></b> speculation of future development in Construction business.</p>
                                    <p>Slow & steadily, but with his dedication, hard work and passion he emerged to become one of the most trusted Civil Contractors in the local area of Kutch district.</p>
                                    <p>On attaining a certain age and to do some Social Service for the Mankind, he voluntarily retired himself from the job, handing over the baton, to his eldest son, Sri Jadavji Vishrambhai Gorasia.</p>
                                    <p>Now, to carry on the legacy of his father, Sri Jadavji Vishrambhai Gorasia, registered the family construction business into a registered private limited company in the year 2008, duly named as <b><i>“VISHRAMBHAI GORASIA CONSTRUCTION PRIVATE LIMITED”</i></b>.</p>
                                </div>
                            </Col>                            
                        </Row>
                    </div>
                </Container>
                <Marquee direction="right" speed="50" gradientWidth={10}>
                    <img src={Vehicle1} alt="road construction company in gujarat | VGCPL" />
                </Marquee>
            </section>
            <section id='projects' >
                <Container>
                    <h2>Our Proud <span>Clients</span></h2><br />
                </Container>
                <Container fluid>
                    <SliderProjects />
                </Container>
            </section>
            <GetInTouch />
            <MissionAndVision />
            <ContactSection />            
        </div>
    )
}

export default Homepage
