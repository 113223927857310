import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle2 from '../assets/images/vgcpl_truck2.svg'
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Marquee from "react-fast-marquee";

export default function ContactSection(props) {
    return (
        <section id='contact' className={props.classname}>
            <Container>
                <Row>
                    <Col md={6} className="bg-3">
                        <h2>Contact <span>Us</span></h2><br />
                        <p>We Are Just A Click Away</p>
                        <div className="contacts">
                            <div class="icon">
                                <PhoneIcon />
                            </div>
                            <a href="tel:+91 9909008176">+91 9909008176</a>
                        </div>
                        <div className="contacts">
                            <div class="icon">
                                <EmailIcon />
                            </div>
                            <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
                        </div>
                        <div className="contacts">
                            <div class="icon">
                                <RoomIcon />
                            </div>
                            <p>REGISTERED OFFICE</p>
                            <p> 54 & 55 ,Patel Nagar, <br /> At. Post: Baladia,<br/> Taluka:Bhuj, <br /> District:Kutch, <br />State:Gujarat, <br />Pincode: 370427</p>
                        </div>
                    </Col>
                    <Col md={6}>
                    <iframe title='VGCPL Registered offic google map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14679.811770697346!2d69.6110052!3d23.0988184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dff8d6a2ee484ac!2sVGCPL!5e0!3m2!1sen!2sin!4v1668682681570!5m2!1sen!2sin" width="600" height="450" style={{border:0,maxWidth:'100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </Container>
            <Marquee direction="right" speed='35' gradientWidth={10}>
                <img src={Vehicle2} alt="construction company in gujarat | VGCPL" />
            </Marquee>
        </section>
    )
}
